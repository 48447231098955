import { defineStore } from 'pinia';
import AuthService from 'src/services/AuthService';
import { UserInfo } from '../types';
import { useLocalStorage } from '@vueuse/core';

// Default state function
const getDefaultState = () => ({
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  type: '',
  avatar: '',
  is_email_verified: false,
  onboardingDone: undefined as Date | null | undefined,
  lastAcknowledgedLevel: 1,
  petName: '', // New Field
  stripe_product_id: '',
  stripe_subscription_is_active: true,
  isAdmin: false,
  agencyId: '',
  agencyName: '',
  agencyLogo: '',
  studentLevel: 1,
  accountType: '',
});

export const useUserInfoStore = defineStore('user-info', {
  state: () => {
    const storedData = JSON.parse(localStorage.getItem('user-info')) || {};

    const mergedState = { ...getDefaultState(), ...storedData };

    localStorage.setItem('user-info', JSON.stringify(mergedState));

    return useLocalStorage('user-info', mergedState);
  },
  getters: {
    fullName: (state) => `${state.firstName} ${state.lastName}`
  },
  actions: {
    async fetchUserInfo() {
        const [error, data] =  await AuthService.getUserInfo();
        if (error) {
          console.error(error);
          return
        }
        if (data) {
          console.log('Data:', data);
          this.id = data.id;
          this.email = data.email;
          this.firstName = data.first_name;
          this.lastName = data.last_name;
          this.type = data.type;
          this.avatar = data.avatar;
          this.is_email_verified = data.is_email_verified;
          this.onboardingDone = data.user_metadata.onboarding_done as Date | null;
          this.lastAcknowledgedLevel = data.user_metadata.last_acknowledged_level;
          this.stripe_product_id = data.stripe_product_id;
          this.isAdmin = data.is_admin ?? false;
          if(!!data.agency_id) {
            this.agencyId = data.agency_id ?? '';
          }
          this.agencyName = data.agency_name ?? '';
          this.agencyLogo = data.agency_logo ?? '';
          this.stripe_subscription_is_active = data.stripe_subscription_is_active;
          this.accountType = data.account_type;
        }
        // Force re-sync with local storage
        const currentState = JSON.parse(localStorage.getItem('user-info') || '{}');
        localStorage.setItem('user-info', JSON.stringify({ ...currentState, ...this.$state }));
    },
    setUserInfo(userData: UserInfo) {
      this.id = userData.id;
      this.email = userData.email;
      this.firstName = userData.first_name;
      this.lastName = userData.last_name;
      this.type = userData.type;
      this.avatar = userData.avatar;
      this.is_email_verified = userData.is_email_verified;
      this.onboardingDone = userData.user_metadata
        ?.onboarding_done as Date | null;
      this.lastAcknowledgedLevel =
        userData.user_metadata?.last_acknowledged_level || 1;
      this.stripe_product_id = userData.stripe_product_id;
      this.stripe_subscription_is_active =
        userData.stripe_subscription_is_active;
      this.isAdmin = userData.is_admin;
      this.agencyId = userData.agency_id;
      this.agencyName = userData.agency_name;
      this.agencyLogo = userData.agency_logo;
      this.accountType = userData.account_type;
    },
    clearUserInfo() {
      this.id = '';
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.type = '';
      this.avatar = '';
      this.is_email_verified = false;
      this.stripe_product_id = '';
      this.stripe_subscription_is_active = true;
      this.isAdmin = false;
      this.stripe_subscription_is_active = true;
      this.agencyId = '';
      this.agencyName = '';
      this.agencyLogo = '';
      this.studentLevel = 1;
      this.accountType = '';
    },
    setIsEmailVerified(isEmailVerified: boolean) {
      this.is_email_verified = isEmailVerified;
    },
    setPetName(petName: string) {
      this.petName = petName;
    },
    setAvatar(avatar: string) {
      this.avatar = avatar;
    },
    setStripeProductId(stripeProductId: string) {
      this.stripe_product_id = stripeProductId;
    },
    setStripeSubscriptionIsActive(stripeSubscriptionIsActive: boolean) {
      this.stripe_subscription_is_active = stripeSubscriptionIsActive;
    }
    ,
    async fetchStripeProductId() {
      const [error, data] = await AuthService.getUserInfo();
      if (error) {
        console.error(error);
        return;
      }
      if (data) {
        this.stripe_product_id = data.stripe_product_id;
        this.stripe_subscription_is_active = data.stripe_subscription_is_active;
      }
    },
    setAgencyInfo(agencyId: string, agencyName: string) {
      this.agencyId = agencyId;
      this.agencyName = agencyName;
    },
    setAgencyId(agencyId: string) {
      this.agencyId = agencyId;
      console.log('agencyId',agencyId)
      console.log('this.agencyId',this.agencyId)
    },
    setAgencyName(agencyName: string) {
      this.agencyName = agencyName;
    },
    setIsAdmin(isAdmin: boolean) {
      this.isAdmin = isAdmin;
    },
    setEmail(email: string) {
      this.email = email;
    },
    setFirstName(firstName: string) {
      this.firstName = firstName;
    },
    setLastName(lastName: string) {
      this.lastName = lastName;
    },
    setStudentLevel(level: number) {
      this.studentLevel = level;
    },
  },
  persist: true,
});
